import axios from 'axios';

class OrderSchoolService {
    getOrder(line_uid) {
        return axios.get(`/orders/${line_uid}`)
                .then(response => {
                  return response
                })
      }
}

export default new OrderSchoolService();
