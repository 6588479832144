<template>
  <div class="home">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <h1>รายการสั่งซื้อ</h1>
              </div>
            </div>
          </v-app-bar>
          <v-container fluid>
            <vue-element-loading
              :active="isLoadOrder"
              spinner="line-scale"
              color="#EF4035"
              class="my-8"
            />
            <v-card class="mb-2" v-for="(data, i) in allOrders" :key="i">
              <div class="container px-2" fluid>
                <div class="card-order-list">
                  <div>
                    <h4 style="font-size: 16px">
                      หมายเลขคำสั่งซื้อ : {{ data.orderNo }}
                    </h4>
                    <h4 style="font-size: 16px">
                      สถานะการสั่งซื้อ :
                      <span
                        style="color: #0288d1; font-size: 16px"
                        class="status-order-list"
                        >{{ data.orderStatus }}</span
                      >
                    </h4>
                    <h5 class="font-weight-regular" style="font-size: 12px">
                      วันที่สั่งซื้อ : {{ data.createDate | dateFormat }}
                    </h5>
                  </div>
                  <v-divider class="mb-2 mt-2"></v-divider>
                  <div
                    class="d-flex"
                    v-for="(item, index) in data.details"
                    :key="index"
                  >
                    <div class="mr-3">
                      <img
                        height="80px"
                        width="85px"
                        :src="item.goods.imagePath"
                        alt="Image Goods"
                      />
                    </div>
                    <div style="width: 100%">
                      <p class="mb-0">{{ item.goods.name }}</p>
                      <p class="mb-0">
                        {{ item.unitPrice | currency }} บาท/{{
                          item.goods.unit
                        }}
                      </p>
                      <p class="mb-0">x{{ item.quantity }}</p>
                    </div>
                  </div>
                  <v-divider class="mt-1 mb-2"></v-divider>
                  <div class="d-flex justify-space-between align-center mb-0">
                    <h4>{{ data.details.length }} รายการ</h4>
                    <h4>
                      รวมการสั่งซื้อ : ฿ {{ data.totalAmountVat | currency2 }}
                    </h4>
                  </div>
                  <v-divider
                    v-if="
                      data.details.some(
                        (item) => item.purchaseStatus === 'APPROVED'
                      )
                    "
                    class="mt-2 mb-2"
                  ></v-divider>
                  <div
                    v-if="
                      data.details.some(
                        (item) => item.purchaseStatus === 'APPROVED'
                      )
                    "
                    
                  >
                    <v-btn
                      @click.prevent="setDetailsReceive(data)"
                      @click="$router.push('/receive')"
                      class="white--text"
                      small
                      color="blue"
                      >อัปโหลดสลิปหลักฐานการชำระเงิน</v-btn
                    >
                    <!-- <v-btn
                      @click.prevent="setDetailsReceive(data)"
                      @click="$router.push('/goods-receipt')"
                      small
                      color="success"
                      >ใบเสร็จ</v-btn
                    > -->
                  </div>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-card>
      </v-container>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import OrderSchoolService from "@/services/orderSchool.service"
import moment from "moment";
import liff from "@line/liff";
export default {
  name: "Order-List",
  data() {
    return {
      isLoadOrder: false,
      allOrders: [],
      line_uid: null,
    };
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm:ss a.");
    },
  },
  // created() {
  //   axios.get(`/orders/U690e93ea9c5bdc0d105896b8b8f0da47`)
  //     .then((res) => {
  //       console.log(res);
  //       this.allOrders = res.data.orders.reverse();
  //     });
  // },
  async created() {
    await liff.init(
      { liffId: "1656130154-Kjg1xwBv" },
      () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          this.liffGetProfile();
        }
      },
      (err) => console.log(err.code, err.message)
    );
  },
  methods: {
    liffGetProfile() {
      liff
        .getProfile()
        .then((profile) => {
          this.line_uid = profile.userId;
          this.getAllOrders();
        })
        .catch((err) => console.log(err));
    },
    getAllOrders() {
      this.isLoadOrder = true;
      OrderSchoolService.getOrder(this.line_uid)
        .then((res) => {
          console.log(res);
          this.isLoadOrder = false;
          this.allOrders = res.data.orders.reverse();
        })
        .catch((err) => {
          this.isLoadOrder = false;
          console.log(err);
        });
    },
    setDetailsReceive(details) {
      this.$store.dispatch("setDetailsReceive", details);
    },
    setOrderDetails(details) {
      this.$store.dispatch("setOrderDetails", details);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
/* ***Header*** */
</style>